import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";

export interface Rewards {
    id?: string;
    network: string;
    rewards: string;

  }

  export interface FetchRewardPayload {
    allReward?: Rewards[]; // Optional, because not all actions will provide projects
    loading?: boolean; // Optional
    error?: Error | string; // Optional

    
  }

  export interface SignupToken {
    tokens?: string; // Optional, because not all actions will provide projects


    
  }
  export interface fetchSignUpRewardPayLoad {
    signupReward?: null | undefined; // Optional, because not all actions will provide projects
    loading?: boolean; // Optional
    error?: Error | string; // Optional

    
  }

  export interface fetchScoringPayLoad{
    scoringToken? : null | undefined;
    loading?: boolean;
    error?: Error | string
  }

  export interface fetchUpdateSingupPayload{
    success? : null | undefined;
    loading?: boolean;
    error?: Error | string
  }


  export interface RewardsState {
    fetching: boolean;
    creating: boolean;
    updating: boolean;
    createError?: Error | string;
    error?: any;
    fetchError?: Error | string;
    allReward: Rewards[] | [];
    allSignUpReward?: any
    scoringToken?:any
    updateSignup?:any
  }
  


  export enum RewardsActions {
    FETCH = "FETCH",
    FETCHED_ALL_REWARDS = "FETCHED_ALL_REWARDS",
    FETCHED_REWARDS = "FETCHED_REWARDS",
    FETCH_ERROR = "FETCH_REWARDS_ERROR",
    CREATE = "CREATE_REWARD",
    CREATE_SUCCESS = "CREATE_REWARD_SUCCESS",
    CREATE_ERROR = "CREATE_REWARD_ERROR",
    UPDATE = "UPDATE_REWARD",
    UPDATE_SUCCESS = "UPDATE_REWARD_SUCCESS",
    UPDATE_ERROR = "UPDATE_REWARD_ERROR",
    FETCH_REWARD_SIGNUP = "FETCH_REWARD_SIGNUP",
    REWARD_SIGNUP_SUCCESS = "FETCH_SIGNUP_SUCCESS",
    REWARDS_SIGNUP_ERROR = "FETCH_REWARD_SUCCESS",
    FETCH_SCORING_TOKEN = "FETCH_SCORING_TOKEN",
    SCORING_TOKEN_SUCCESS = "SCORING_TOKEN_SUCCESS",
    SCORING_TOKEN_ERROR = "SCORING_TOKEN_ERROR",
    UPDATE_SIGNUP_REWARD = "UPDATE_SIGNUP_REWARD",
    UPDATE_SIGNUP_REWARD_SUCCESS= "UPDATE_SIGNUP_REWARD_SUCCESS",
    DELETE = "DELETE_REWARD",
    DELETE_SUCCESS = "DELETE_SUCCESS",
    DELETE_ERROR = "DELETE_ERROR"
  }
  
  interface RewardsActionsType<T, P = {}> {
    type: T;
    payload?: P;
  }
  
  export type RewardsAction =
    | RewardsActionsType<typeof RewardsActions.FETCH>
    | RewardsActionsType<typeof RewardsActions.FETCHED_ALL_REWARDS, FetchRewardPayload>
    | RewardsActionsType<typeof RewardsActions.CREATE>
    | RewardsActionsType<typeof RewardsActions.CREATE_SUCCESS>
    | RewardsActionsType<typeof RewardsActions.CREATE_ERROR, Error | string>
    | RewardsActionsType<typeof RewardsActions.UPDATE>
    | RewardsActionsType<typeof RewardsActions.UPDATE_SUCCESS>
    | RewardsActionsType<typeof RewardsActions.UPDATE_ERROR, Error | string>
    | RewardsActionsType<typeof RewardsActions.DELETE>
    | RewardsActionsType<typeof RewardsActions.DELETE_SUCCESS>
    | RewardsActionsType<typeof RewardsActions.FETCH_REWARD_SIGNUP>
    | RewardsActionsType<typeof RewardsActions.REWARD_SIGNUP_SUCCESS, fetchSignUpRewardPayLoad>
    | RewardsActionsType<typeof RewardsActions.FETCH_SCORING_TOKEN>
    | RewardsActionsType<typeof RewardsActions.SCORING_TOKEN_SUCCESS, fetchScoringPayLoad>
    | RewardsActionsType<typeof RewardsActions.UPDATE_SIGNUP_REWARD>
    | RewardsActionsType<typeof RewardsActions.UPDATE_SIGNUP_REWARD_SUCCESS,  fetchUpdateSingupPayload>
    | RewardsActionsType<typeof RewardsActions.DELETE_ERROR, Error | string>
 
    | RewardsActionsType<
        typeof RewardsActions.FETCHED_REWARDS,
        Rewards[]
      >
    | RewardsActionsType<typeof RewardsActions.FETCH_ERROR, any>
  
  export type RewardsThunk = ThunkAction<
    void,
    RootState,
    unknown,
    RewardsAction
  >;
  export type RewardsDispatch = ThunkDispatch<
    RootState,
    any,
    RewardsAction
  >;