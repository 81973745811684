
  import { History, LocationState } from "history";
  import { QuestionRepo } from "../repositories/question";
import { callableFunctions } from "../repositories/firebase";
import { FaqActions, FaqThunk } from "../model/faq";


  export const fetchAllFaqs = (): FaqThunk => {
    return async (dispatch, getState) => {
      const { uid, email } = getState().firebase.auth;
  
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: FaqActions.FETCH, payload: { loading: true } });
  
      try {
        // Call Firebase callable function to fetch all projects
        const fetchAllFaqs = callableFunctions.httpsCallable('fetchAllFaqs');
        const res = await fetchAllFaqs();
  
        // Debugging response
        console.debug("Response from fetchAllProject", res);
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: FaqActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
          const faqs  = res.data;
  
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: FaqActions.FETCHED_ALL,
            payload: {
              allFaq:faqs,
              loading: false,
                                                                                                                                                                                                                                                 
            }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: FaqActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };


  export const createFaq = (data, history: History<LocationState>,): FaqThunk => {
   
    let values ={...data,date:new Date()}
    return async (dispatch, getState) => {
      dispatch({ type: FaqActions.CREATE });
      const createFaq = callableFunctions.httpsCallable('createFaq');
      const res = await createFaq(values);
      if (res instanceof Error)
        dispatch({ type: FaqActions.CREATE_ERROR, payload: res });
      else {
         dispatch({ type: FaqActions.CREATE_SUCCESS, payload: res });
         if(res.data.success== true){
          history.push("/app/faqs");
         }

      }
    };
  };

  export const updateFaqs = (data, id, history): FaqThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: FaqActions.UPDATE });

      const updateFaq = callableFunctions.httpsCallable('updateFaq');
      const res = await updateFaq({updateData:data, id});
      // const res = await QuestionRepo.create(data);
      // console.debug(res,"prject action response")
      if (res instanceof Error)
        dispatch({ type: FaqActions.UPDATE_ERROR, payload: res });
      else{ dispatch({ type: FaqActions.UPDATE_SUCCESS, payload: res });
      if(res.data.success== true){
        history.push("/app/faqs");
       }
               }
    };
  };

  export const deleteFaq = (id, history: History<LocationState>,): FaqThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: FaqActions.DELETE });

      const deleteFaqs = callableFunctions.httpsCallable('deleteFaqs');
      const res = await deleteFaqs({id:id});
      // const res = await QuestionRepo.create(data);
      console.debug(res.data,"prject action response")
      if (res instanceof Error)
        dispatch({ type: FaqActions.DELETE_ERROR, payload: res });
      else{ dispatch({ type: FaqActions.DELETE_SUCCESS, payload: res });
      if(res.data.success== true){
        window.location.reload();
       }
               }
    };
  };
