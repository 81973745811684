import React, { Dispatch, useEffect,useState } from "react";
import useStyles from './styles';
import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,Paper,Button
} from "@material-ui/core";
import RewardModal from "./components/RewardModal";
import RewardModalAdd from "./components/RewardModalAdd";
import {reward} from '../../repositories/reward';
import { Typography } from "../../components/Wrappers/Wrappers";
import { TextField } from "@material-ui/core";
import { RewardsDispatch } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRewards, fetchRewardSignUps, fetchScoringToken, updateScoringToken, updateSignUpReward } from "../../actions/rewards";


function Rewards(){
	const classes = useStyles();
    const [value,setValue] = useState({});
    const [token,setToken] = useState('0');
    const [stat,setStat] = useState(false);
    const [stat1,setStat1] = useState(false);
    const dispatchRewards = useDispatch<RewardsDispatch>();
    const [scoreToken,setScoreToken] = useState('0');
    const [open,setOpen] = useState(false);
    const [addOpen,setAddOpen] = useState(false);
    const [data, setData]= useState([])

    const authToken = useSelector(state =>  state.user.token)
    const allReward= useSelector(state =>  state.rewards?.allReward)
    const singUpRewardToken = useSelector(state => state.rewards.allSignUpReward)
    const scoringToken = useSelector(state =>  state.rewards?.scoringToken)

   
    useEffect(()=>{
      
      if(authToken){ 
      dispatchRewards(fetchAllRewards(authToken))
      dispatchRewards(fetchRewardSignUps(authToken))
      dispatchRewards(fetchScoringToken(authToken))
    }
    },[authToken])

    useEffect(()=>{
      if(allReward){
        setData(allReward?.data)
      }
      if(singUpRewardToken){
        setToken(singUpRewardToken.tokens);
            setStat(true)
            if(scoringToken){
              console.debug("hjbxhjxhj", scoringToken?.tokens)
              setScoreToken(scoringToken?.tokens);
                setStat1(true)
            }
      }
  

    },[allReward, singUpRewardToken, scoringToken])

    function deleteHandler(id)
    {
        reward.delete(id).then((res)=>{
            window.location.reload();
        })
    }

    if(!stat)
    return <h1>loading...</h1>
    // if(!stat1)
    // return <h1>loading...</h1>
return(
    <>
    <TableContainer component={Paper} className={classes.container}>
      <Table style={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tier</TableCell>
            <TableCell>network size</TableCell>
            <TableCell>rate per hour</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.debug("wjkjks", data)}
          {data?.length>0 && data.map((row,index) => (
            <TableRow
              key={row.id}
            >
              <TableCell component="th" scope="row">
                {row.tier}
              </TableCell>
              <TableCell>
                {row.network}
              </TableCell>
              <TableCell>
                {row.rewards}
              </TableCell>
              <TableCell>
                <Button color="primary"
                    size="small"
                    type="button" onClick={()=>{
                        setOpen(true);
                        setValue(row);
                    }}>edit</Button>
              </TableCell>
              <TableCell>
                <Button color="primary"
                    size="small"
                    type="button" onClick={()=>deleteHandler(row.id)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button className={classes.background} onClick={()=>{setAddOpen(true)}} >Add</Button>

    <RewardModalAdd addOpen={addOpen} setAddOpen={setAddOpen} length={data?.length}/>
    {open&&<RewardModal open={open} setOpen={setOpen} data={value}/>}

    <div>
        <Typography>Sign-up Rewards</Typography>
        <TextField defaultValue={token} variant='outlined' onChange={(e)=>{setToken(e.target.value)}}></TextField>
        <Button className={classes.background} onClick={()=>{
        dispatchRewards(updateSignUpReward(token))
        }}>save</Button>
    </div>

    <div>
        <Typography>Scoring Rewards</Typography>
        <TextField value={scoreToken} variant='outlined' onChange={(e)=>{setScoreToken(e.target.value)}}></TextField>
        <Button className={classes.background} onClick={()=>{
 
        dispatchRewards(updateScoringToken(scoreToken))
        }}>save</Button>
    </div>
    </>
);

}

export default Rewards;