import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import {
	Button,
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import { QuestionList } from "../../components/QuestionList";
import { RootState } from "../../reducers";
import { fetchAllQuestions } from "../../actions/question";
import { QuestionDispatch, Questions, SearchAction } from "../../model";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Filter } from "../../utils/enums";

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
		fontWeight: "bold",
	},
}))(TableCell);

function getDefaultSorting(search: string): Filter {
	const sorting = new URLSearchParams(search).get("section");
	switch (sorting) {
		case "team": return Filter.team;
		case "idea": return Filter.idea;
		case "market": return Filter.market;
		case "finance": return Filter.finance;
		case "competition": return Filter.competition;
		case "esg": return Filter.esg;
		case "crypto": return Filter.crypto;
		default: return Filter.default;
	}
}

function Dashboard() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const keys = [
		{ id: 1, label: "" },
		{ id: 2, label: "Question" },
		{ id: 3, label: "Platform" },
		{ id: 4, label: "Type" },
		{ id: 5, label: "Comment" },
		{ id: 6, label: "Active" },
		{ id: 7, label: "Section" },
		{ id: 8, label: "Crypto" },
		{ id: 9, label: "Action" },
	];

	const { allQuestions, fetching, nextPageToken, hasMore } = useSelector(
		(state: RootState) => state.question
	);
	const search = useSelector((state: RootState) => state.search);
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const user = useSelector((state: RootState) => state.firebase.auth);

	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const dispatchQuestion = useDispatch<QuestionDispatch>();

	const [filter, setFilter] = useState<Filter>(getDefaultSorting(location.search));
	const [limit, setLimit] = useState(12);
	const [questions, setQuestions] = useState<Questions[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");

	useEffect(() => {
		// Fetch questions based on filter and limit
		dispatchQuestion(fetchAllQuestions(limit, null, filter));
	}, [dispatchQuestion, limit, filter]);

	useEffect(() => {
		// Update search in the store when the searchQuery changes
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch, searchQuery]);

	useEffect(() => {
		// Update filter based on URL parameter when location changes
		setFilter(getDefaultSorting(location.search));
	}, [location]);

	useEffect(() => {
		// Update questions whenever allQuestions or search state changes
		setQuestions(
			allQuestions.filter((v) =>
				v.question.toLowerCase().includes(search.searchString.toLowerCase())
			)
		);
	}, [allQuestions, search.searchString]);

	const handleLoadMore = () => {
		if (!fetching && hasMore) {
			dispatchQuestion(fetchAllQuestions(limit, nextPageToken, filter));
		}
	};

	const userName = user.displayName?.split(" ")[0] ?? "there!";

	return (
		<>
			<Fab
				className={classes.fab}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={() => history.push("/create")}
			>
				<AddIcon />
				New Question
			</Fab>
			<Fab
				className={classes.fab1}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={() => history.push("/create-faq")}
			>
				<AddIcon />
				New FAQ Question
			</Fab>
			<>
				<PageTitle
					noMargin
					title={
						new Date().getHours() > 12
							? `Good afternoon ${userName}`
							: `Good morning ${userName}`
					}
				/>
				<div className={classes.container}>
					<TableContainer>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									{keys.map((key) => (
										<StyledTableCell key={key.id}>{key.label}</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{questions.length>0 && questions.map((v, index) => (
									<QuestionList key={index} data={v} />
								))}
							</TableBody>
						</Table>
						{hasMore && (
							<div style={{ textAlign: "center", marginTop: "20px" }}>
								<Button
									onClick={handleLoadMore}
									disabled={fetching}
									variant="contained"
									color="primary"
								>
									{fetching ? "Loading..." : "Load More"}
								</Button>
							</div>
						)}
					</TableContainer>
				</div>
			</>
		</>
	);
}

export default Dashboard;
