import {
    QuestionActions,
    QuestionThunk,
    Questions,
  } from "../model";
  import { History, LocationState } from "history";
  import { QuestionRepo } from "../repositories/question";
import { callableFunctions } from "../repositories/firebase";
import { useHistory } from "react-router-dom";



  export const fetchAllQuestions = (limit: number | null, nextPageToken: string | null = null, filter:string| null = null): QuestionThunk => {
    return async (dispatch, getState) => {
      const { uid, email } = getState().firebase.auth;
  
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: QuestionActions.FETCH, payload: { loading: true } });
  
      try {
        // Call Firebase callable function to fetch all projects
        const fetchAllQuestions = callableFunctions.httpsCallable('fetchAllQuestions');
        const res = await fetchAllQuestions({limit: limit, pageToken: nextPageToken, filter:filter});
  
        // Debugging response
        console.debug("Response from fetchAllProject", res);
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: QuestionActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
          const { questions, nextPageToken: newNextPageToken, hasMore } = res.data;
  
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: QuestionActions.FETCHED_ALL,
            payload: {
              filter,
              questions,
              nextPageToken: newNextPageToken,
              hasMore,
              loading: false,
                                                                                                                                                                                                                                                 
              
            }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: QuestionActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const createQuestion = (data:Questions, history: History<LocationState>,): QuestionThunk => {
   
    return async (dispatch, getState) => {
      dispatch({ type: QuestionActions.CREATE });
      const createQuestion = callableFunctions.httpsCallable('createQuestion');
      const res = await createQuestion({question:data});
      console.debug(res,"prject action response")
      if (res instanceof Error)
        dispatch({ type: QuestionActions.CREATE_ERROR, payload: res });
      else {
         dispatch({ type: QuestionActions.CREATE_SUCCESS, payload: res });
         if(res.data.success== true){
          history.push("/app/dashboard");
         }

      }
    };
  };


  export const updateQuestion = (data:Questions, id): QuestionThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: QuestionActions.UPDATE });

      const updateQuestion = callableFunctions.httpsCallable('updateQuestion');
      const res = await updateQuestion({updateData:data, id});
      // const res = await QuestionRepo.create(data);
      // console.debug(res,"prject action response")
      if (res instanceof Error)
        dispatch({ type: QuestionActions.UPDATE_ERROR, payload: res });
      else{ dispatch({ type: QuestionActions.UPDATE_SUCCESS, payload: res });
      console.debug(res,"prject action response")
               }
    };
  };

  export const deleteQuestion = (id, history: History<LocationState>,): QuestionThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: QuestionActions.DELETE });

      const deleteQuestion = callableFunctions.httpsCallable('deleteQuestion');
      const res = await deleteQuestion({id:id});
      // const res = await QuestionRepo.create(data);
      console.debug(res.data,"prject action response")
      if (res instanceof Error)
        dispatch({ type: QuestionActions.DELETE_ERROR, payload: res });
      else{ dispatch({ type: QuestionActions.DELETE_SUCCESS, payload: res });
      if(res.data.success== true){
        window.location.reload();
       }
               }
    };
  };

  export const questionResponse = (id, history: History<LocationState>,): QuestionThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: QuestionActions.QUESTION_RESPONSE });

      const fetchQuestionResponse = callableFunctions.httpsCallable('fetchQuestionResponse');
      const res = await fetchQuestionResponse({id:id});
      // const res = await QuestionRepo.create(data);
      console.debug(res.data,"prject action response")
      if (res instanceof Error)
        dispatch({ type: QuestionActions.QUESTION_RESPONSE_ERROR, payload: res });
      else{ dispatch({ type: QuestionActions.QUESTION_RESPONSE_SUCCESS, payload: res.data });
      // if(res.data.success== true){
      //   window.location.reload();
      //  }
               }
    };
  };

  