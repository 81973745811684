import React, { Dispatch, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button as MButton,
} from "@material-ui/core";
import { useCounter } from "ahooks";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import {QuestionNewForm}  from "./components";
import {QuestionForm}  from "./components";
import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { QuestionDispatch, Questions } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import {createQuestion} from "../../actions/question";

const validationSchema = Yup.object().shape<Questions>({
    question: Yup.string().required("this field is necessary"),
    type: Yup.string().required("this field is necessary"),
    comment: Yup.boolean(),
    active: Yup.boolean(),
    crypto: Yup.boolean(),
    section: Yup.string().required("this field is necessary"),
    stage: Yup.string().required("this field is necessary"),
    platform: Yup.string().required("this field is necessary"),
    context: Yup.string(),
  
});

const intitialValues: Questions = {
  question: "",
  type: "",
  comment: false,
  active: false,
  crypto: false,
  section: "",
  stage: "",
  platform: "",
  context: "",
};

function Create() {
  const classes = useStyles();
  const history = useHistory();
  const { creating, createError } = useSelector(
    (state: RootState) => state.question
  );
  const dispatch = useDispatch<QuestionDispatch>();

  // function getButtons(formik: FormikProps<Questions>) {
     
  //     return (
  //       <>
  //         <Button
  //           disabled={!formik.isValid}
  //           type="button"
  //           onClick={() => formik.submitForm()}
  //         >
  //           Submit
  //         </Button>
  //       </>
  //     );
  // }

  if (creating) return <Loader text="Creating project..." />;
  if (createError) return <ErrorDisplay error={createError} />;

  return (
    <div className={classes.root}>
      <Formik<Questions>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createQuestion(values, history));
          // if(!creating && !createError) history.push("/app/dashboard");
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              {/* <AppBar elevation={0} position="static" color="transparent">
                <Toolbar className={classes.toolbar}>
                  <MButton color="inherit" onClick={() => history.goBack()}>
                    Cancel
                  </MButton>
                </Toolbar>
              </AppBar> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <div className={classes.stepperContainer}> */}
                  <QuestionNewForm/>
                  {/* <QuestionForm/> */}
                  <div style={{ marginBottom: 40 }} />
                  {/* {getButtons(formik)} */}
                  {/* </div> */}
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Create;
