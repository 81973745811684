import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { ScoreForm } from ".";

export interface Questions {
    id?: string;
    question: string;
    type: string;
    comment: boolean;
    active: boolean;
    crypto: boolean;
    section: string;
    stage: string;
    platform: string;
    context: string;
    responses?: ScoreForm[];
  }

  export interface Faqs {
    id?: string;
    question: string;
    context: string;
    platform: string;
    // responses?: ScoreForm[];
  }

  export interface TechnicalFactor {
    id?: string;
    value: string;
    section: string;
    active: boolean;
    label: string;
    weight: number;
    options: Record<string,any>[];
    did?: string;
    // responses?: ScoreForm[];
  }

  export interface Option {
    id?: string;
    value: string;
    score: number;
    comment?: string;
    // responses?: ScoreForm[];
  }
  export interface FetchQuestionPayload {
    filter?:string;
    questions?: Questions[]; // Optional, because not all actions will provide projects
    nextPageToken?: string | null; // Optional
    hasMore?: boolean; // Optional
    loading?: boolean; // Optional
    error?: Error | string; // Optional
    previousFilter?:string| null | undefined
    // previousSortBy?:string| null | undefined
    // previousSortType?:string| null | undefined
    
  }

  interface Response {
    event_id?:string;
    event_type?: string;


  }
  export interface FetchQuestionResponse {
    active?:string;
    comment?: string;
    context?:string;
    crypto?: string;
    date?: string;
    platform? : string;
    question? : string;
    responses?: []

  }
  
  

  export interface QuestionState {
    fetching: boolean;
    creating: boolean;
    filter:string | null;
    createError?: Error | string;
    error?: any;
    fetchError?: Error | string;
    allQuestions: Questions[] | [];
    removeFavouriteError?: Error | string;
    nextPageToken: string | null; // For pagination
    hasMore: boolean;             // To track if more projects are available
    previousFilter: string | null| undefined;
    previousSortBy:string | null| undefined;
    previousSortType:string | null| undefined;
    response:any;
  }
  

  export enum QuestionActions {
    FETCH = "FETCH_ALL_QUESTIONS",
    FETCHED_ALL = "FETCHED_ALL_QUESTIONS",
    FETCHED_QUESTIONS = "FETCHED_QUESTIONS",
    FETCH_ERROR = "FETCH_QUESTIONS_ERROR",
    CREATE = "CREATE_QUESTION",
    CREATE_SUCCESS = "CREATE_QUESTION_SUCCESS",
    CREATE_ERROR = "CREATE_QUESTION_ERROR",
    QUESTION_RESPONSE = "QUESTION_RESPONSE",
    QUESTION_RESPONSE_ERROR = "QUESTION_RESPONSE_ERROR",
    QUESTION_RESPONSE_SUCCESS = "QUESTION_RESPONSE_SUCCESS",
    UPDATE = "UPDATE_QUESTION",
    UPDATE_SUCCESS = "UPDATE_QUESTION_SUCCESS",
    UPDATE_ERROR = "UPDATE_QUESTION_ERROR",
    DELETE = "DELETE_QUESTION",
    DELETE_SUCCESS = "DELETE_SUCCESS",
    DELETE_ERROR = "DELETE_ERROR"
  }
  
  interface QuestionActionsType<T, P = {}> {
    type: T;
    payload?: P;
  }
  
  export type QuestionAction =
    | QuestionActionsType<typeof QuestionActions.FETCH>
    | QuestionActionsType<typeof QuestionActions.FETCHED_ALL, FetchQuestionPayload>
    | QuestionActionsType<typeof QuestionActions.CREATE>
    | QuestionActionsType<typeof QuestionActions.CREATE_SUCCESS>
    | QuestionActionsType<typeof QuestionActions.CREATE_ERROR, Error | string>
    | QuestionActionsType<typeof QuestionActions.UPDATE>
    | QuestionActionsType<typeof QuestionActions.UPDATE_SUCCESS>
    | QuestionActionsType<typeof QuestionActions.UPDATE_ERROR, Error | string>
    | QuestionActionsType<typeof QuestionActions.DELETE>
    | QuestionActionsType<typeof QuestionActions.DELETE_SUCCESS, FetchQuestionResponse>
    | QuestionActionsType<typeof QuestionActions.DELETE_ERROR, Error | string>
    | QuestionActionsType<typeof QuestionActions.QUESTION_RESPONSE>
    | QuestionActionsType<typeof QuestionActions.QUESTION_RESPONSE_SUCCESS>
    | QuestionActionsType<typeof QuestionActions.QUESTION_RESPONSE_ERROR, Error | string>
    | QuestionActionsType<
        typeof QuestionActions.FETCHED_QUESTIONS,
        Questions[]
      >
    | QuestionActionsType<typeof QuestionActions.FETCH_ERROR, any>
  
  export type QuestionThunk = ThunkAction<
    void,
    RootState,
    unknown,
    QuestionAction
  >;
  export type QuestionDispatch = ThunkDispatch<
    RootState,
    any,
    QuestionAction
  >;