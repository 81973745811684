import React, { Dispatch, useEffect,useState } from "react";
import {
	Button,TextField
} from "@material-ui/core";
import useStyles from '../styles';
import Modal from "antd/lib/modal/Modal";
import {reward} from "../../../repositories/reward"
import { RewardsDispatch } from "../../../model";
import { useDispatch } from "react-redux";
import { updateRewards } from "../../../actions/rewards";
function RewardModal(props)
{
    const[network,setNetwork]=useState(props.data.network);
   const[rewards,setRewards]=useState(props.data.rewards);
   const dispatchRewards = useDispatch<RewardsDispatch>();
    const classes = useStyles();
    const handleClose = ()=>{
        props.setOpen(false)
    }
    const submitForm = ()=>{

      dispatchRewards(updateRewards({network:network,rewards:rewards,id:props.data.id}))

    }
return(
    <>
        <Modal
          title="Edit"
          centered
          className={classes.modalTeam}
          zIndex={2}
          visible={props.open}
            onCancel={handleClose}
          footer={[
            <Button key="back"
              onClick={handleClose}
            >
              Cancel
            </Button>,
            <Button onClick={submitForm}
            >
              Submit
            </Button>
          ]}
        >
            <div>
            <TextField id="outlined-basic" label="network size" variant="outlined" defaultValue={props.data.network} onChange={(e)=>{setNetwork(e.target.value)}}/>
            </div>
            <div>
            <TextField id="outlined-basic" label="rate/hour" variant="outlined" defaultValue={props.data.rewards} onChange={(e)=>{setRewards(e.target.value)}}/>
            </div>
        </Modal>
</>
)
}

export default RewardModal;