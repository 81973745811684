
import { useSelector } from "react-redux";
import { RewardsThunk, RewardsActions } from "../model"
import { callableFunctions } from "../repositories/firebase";
import { getAuthToken } from "../service/getAuthToken.service";
import axios from "axios";
import { History, LocationState } from "history";


export const fetchAllRewards = (token:string): RewardsThunk => {
    return async (dispatch, getState) => {
      
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: RewardsActions.FETCH, payload: { loading: true } });
      try {
        // Call Firebase callable function to fetch all projects
       
        
        const res = await axios.get("https://us-central1-invluencer-v2-a22c3.cloudfunctions.net/fetchRewards", {
          headers: {
            Authorization: `Bearer ${token}` // Send the token as a Bearer token
          }
        });
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
          
  
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: RewardsActions.FETCHED_ALL_REWARDS,
            payload: {
             
              allReward:res.data,
           
              loading: false,
                                                                                                                                                                                                                                                 
              
            }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const createRewards = (data): RewardsThunk => {
 
    return async (dispatch, getState) => {
      dispatch({ type: RewardsActions.CREATE });
      const createReward = callableFunctions.httpsCallable('createReward');
      const res = await createReward(data);
      // console.debug(res,"prject action response")
      if (res instanceof Error)
        dispatch({ type: RewardsActions.CREATE_ERROR, payload: res });
      else {
        const d = res.data
         dispatch({ type: RewardsActions.CREATE_SUCCESS, payload: res });
         console.debug(d.rewards,"prject action response")
         if(d.rewards.success== true){
          window.location.reload();
         }
      
      }
    };
  };

  export const fetchRewardSignUps = (token:string): RewardsThunk => {
    return async (dispatch, getState) => {
      
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: RewardsActions.FETCH_REWARD_SIGNUP, payload: { loading: true } });
      try {
        // Call Firebase callable function to fetch all projects
       
        
        const res = await axios.get("https://us-central1-invluencer-v2-a22c3.cloudfunctions.net/fetchSignUpTokens", {
          headers: {
            Authorization: `Bearer ${token}` // Send the token as a Bearer token
          }
        });
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
         
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: RewardsActions.REWARD_SIGNUP_SUCCESS,
            payload: {
              signupReward:res.data.data,
              loading: false,
                                                                                                                                                                                                                                                 
              
            }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const fetchScoringToken = (token:string): RewardsThunk => {
    return async (dispatch, getState) => {
      
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: RewardsActions.FETCH_SCORING_TOKEN, payload: { loading: true } });
      try {
        // Call Firebase callable function to fetch all projects
       
        
        const res = await axios.get("https://us-central1-invluencer-v2-a22c3.cloudfunctions.net/fetchScoreTokens", {
          headers: {
            Authorization: `Bearer ${token}` // Send the token as a Bearer token
          }
        });
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
         
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: RewardsActions.SCORING_TOKEN_SUCCESS,
            payload: {
              scoringToken:res.data.data,
              loading: false,
                                                                                                                                                                                                                                                 
              
            }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const updateSignUpReward = (data): RewardsThunk => {
    return async (dispatch, getState) => {
      
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: RewardsActions.UPDATE_SIGNUP_REWARD, payload: { loading: true } });
      try {
        // Call Firebase callable function to fetch all projects
        const updateSignUpReward = callableFunctions.httpsCallable('updateSignUpReward');
      const res = await updateSignUpReward({tokens:data});
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
          window.location.reload();
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: RewardsActions.UPDATE_SIGNUP_REWARD_SUCCESS,
            payload:{
              success:res.data.success
            }
            // payload: {
            //   :res.data.data,
            //   loading: false,
                                                                                                                                                                                                                                                 
              
            // }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const updateScoringToken = (data): RewardsThunk => {
    return async (dispatch, getState) => {
      
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: RewardsActions.UPDATE_SIGNUP_REWARD, payload: { loading: true } });
      try {
        // Call Firebase callable function to fetch all projects
        const updateScoringToken = callableFunctions.httpsCallable('updateScoringToken');
      const res = await updateScoringToken({tokens:data});
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
          window.location.reload();
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: RewardsActions.UPDATE_SIGNUP_REWARD_SUCCESS,
            payload:{
              success:res.data.success
            }
            // payload: {
            //   :res.data.data,
            //   loading: false,
                                                                                                                                                                                                                                                 
              
            // }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const updateRewards = (data): RewardsThunk => {
    return async (dispatch, getState) => {
      
      // Dispatch the FETCH action to notify the state that fetching is in progress
      dispatch({ type: RewardsActions.UPDATE, payload: { loading: true } });
      try {
        // Call Firebase callable function to fetch all projects
        const updateReward = callableFunctions.httpsCallable('updateReward');
      const res = await updateReward(data);
  
        // Check if the result contains an error message
        if (res.data instanceof Error) {
          dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: res.data.message } });
        } else {
          window.location.reload();
          // Dispatch action to append the new projects to the existing list
          dispatch({
            type: RewardsActions.UPDATE_SUCCESS,
            payload:{
              success:res.data.success
            }
            // payload: {
            //   :res.data.data,
            //   loading: false,
                                                                                                                                                                                                                                                 
              
            // }
          });
        }
      } catch (error) {
        // Dispatch FETCH_ERROR action if any error occurs
        dispatch({ type: RewardsActions.FETCH_ERROR, payload: { error: error.message } });
      }
    };
  };

  export const deleteRewards = (id, history: History<LocationState>,): RewardsThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: RewardsActions.DELETE });

      const deleteQuestion = callableFunctions.httpsCallable('deleteQuestion');
      const res = await deleteQuestion({id:id});
      // const res = await QuestionRepo.create(data);
      console.debug(res.data,"prject action response")
      if (res instanceof Error)
        dispatch({ type: RewardsActions.DELETE_ERROR, payload: res });
      else{ dispatch({ type: RewardsActions.DELETE_SUCCESS, payload: res });
      if(res.data.success== true){
        window.location.reload();
       }
               }
    };
  };