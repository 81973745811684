import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography, Button, Menu, MenuItem, Box, CircularProgress, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import PageTitle from '../../components/PageTitle/PageTitle';
import FormDialog from '../../components/Modals/FormDailog';
import { callableFunctions } from '../../repositories/firebase';
import useStyles from "./styles";
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory } from 'react-router-dom';
import axios from "axios";
// import ImportDomainDialog from '../../components/Modals/ImportDomainDialog';
import debounce from 'lodash.debounce';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.common.white,
      },
    },
  })
)(TableRow);

interface Notification {
  id: string;
  title: string;
  notificationText: string;
  projectId:string
}

const NotificationV1 = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.firebase.auth);
  const [allNotification, setAllNotification] = useState<Notification[]>([]);
  const [filterNotification, setFilterNotification] = useState<Notification[]>([]);
  const [pageSize, setPageSize] = useState<number | null>(10);

  const [pageToken, setPageToken] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImportOpen, setDialogImportOpen] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [actionType, setActionType] = useState<string>('');
  const [initialValues, setInitialValues] = useState<Notification | {}>({});
  const [flag, setFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const authToken = useSelector(state =>  state.user.token)
  const userName = user.displayName?.split(" ")[0] ?? "there!";

  useEffect(() => {
    loadNotification(authToken, pageSize, pageToken);
  }, [flag]);


  const loadNotification = async (authToken, pageSize, pageToken) => {
    console.debug("searchOnChangeHanlder: if :loadBlockedDomains chalega",hasMore,fetching)
    if (!hasMore || fetching) return;
  
    setFetching(true);
    setSearchQuery('')
    try {

					const result = await axios.get(
			  "https://us-central1-invluencer-v2-a22c3.cloudfunctions.net/getNotificationList",
			  {
				headers: {
				  Authorization: `Bearer ${authToken}`, // Include the Bearer token for authentication
				},
				params: {
					pageSize,   // Include the page size as a query parameter
					pageToken: pageToken,  // Include the page token as a query parameter
				  },
			  }
			);
      const { notifications, nextPageToken, hasMore: moreData } = result.data;
  
      console.debug('Fetched notifications', { notifications, nextPageToken, moreData });
  
      if (flag) {
        setAllNotification(notifications);  // Reset the domains if flag is true
        setFilterNotification(notifications);
      } else {
        setAllNotification(prevNotification => [...prevNotification, ...notifications]); // Append the new domains to the existing ones
        setFilterNotification(prevNotification => [...prevNotification, ...notifications]);
      }
  
      setPageToken(nextPageToken);
      setHasMore(moreData);
      setFlag(false);
    } catch (error) {
      console.error('Error loading blocked domains:', error);
    }
    setFetching(false);
  };
  

  const handleSearch = debounce(async (searchText: string) => {
    if (fetching) return;

    setFetching(true);
    try {
      const searchBlockedDomains = callableFunctions.httpsCallable('searchBlockedDomains');
      const result = await searchBlockedDomains({ searchTerm: searchText, pageSize: 10, pageToken: '' });
      const { results, nextPageToken, hasMore: moreData } = result.data;

      setFilterNotification(results);
      setPageToken(nextPageToken);
      // setHasMore(moreData);
    } catch (error) {
      console.error('Error searching blocked domains:', error);
    }
    setFetching(false);
  }, 500);

  const handleLoadMore = () => {
    if (!fetching) {
		loadNotification(authToken, pageSize, pageToken);
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement>, notification: Notification) => {
    setAnchorEl(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };





  const handleDelete = async (blocked: Notification) => {
    setDeleteLoader(true);
    const id = blocked.id;
    try {
      const result = await deleteBlockedDomain(id);
      console.debug(result.message);
      setFlag(true);
      handleClose();
      setDeleteLoader(false);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const deleteBlockedDomain = async (id: string) => {
    const deleteDomainFunction = callableFunctions.httpsCallable('deleteBlockedDomain');
    try {
      const result = await deleteDomainFunction({ id });
      console.log('Document deleted successfully:', result.data);
      return result.data;
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error;
    }
  };

  const updateBlockedDomain = async (id: string, name?: string, activeStatus?: string) => {
    const updateDomainFunction = callableFunctions.httpsCallable('updateBlockedDomain');
    try {
      const result = await updateDomainFunction({ id, name, activeStatus });
      console.log(result.message);
      return result.data;
    } catch (error) {
      console.error('Error updating document:', error);
      throw error;
    }
  };

  const searchOnChangeHanlder =(e)=>{
    console.debug("searchOnChangeHanlder", e.target.value)
    setSearchQuery(e.target.value)
    if(e.target.value == ""){ 
      console.debug("searchOnChangeHanlder: if :loadBlockedDomains chalega", e.target.value)
      setFlag(true);
      setFetching(false)
    }else{
      console.debug("searchOnChangeHanlder: else :handleSearch chalega", e.target.value)
      handleSearch(e.target.value);
    }
  }

  const handleUploadSuccess = () => {
    setDialogImportOpen(false);
    setFlag(true);
  };

  const handleUploadError = (error: string) => {
    console.error('Error uploading file:', error);
  };

  const redirectToCreateNotification = () => {
    return history.push("/create-notification")
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" margin="20px 0">
        <PageTitle
          noMargin
          title={
            new Date().getHours() > 12
              ? "Good afternoon " + userName
              : "Good morning " + userName
          }
        />
        <Box display="flex" alignItems="center">
          <TextField
            label="Search Notification"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => searchOnChangeHanlder(e)}
            style={{ marginRight: 16 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={redirectToCreateNotification}
          >
            <AddIcon />
            Add Notification
          </Button>
        </Box>
      </Box>
      <TableContainer className={classes.tableContainer}>
        {fetching && allNotification.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={48} />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Notification Title</TableCell>
				<TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterNotification.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center">No Notification found.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filterNotification.map((notification) => (
                  <StyledTableRow key={notification.id}>
                    <TableCell>{notification.title}</TableCell>
                    <TableCell >
                     {notification.notificationText}
                    </TableCell>
					<TableCell >
                     {notification.projectId}
                    </TableCell>
                    <TableCell>
                      <Button aria-controls="simple-menu" color="primary" onClick={(event) => handleClick(event, notification)}>
                        Action
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                    
                        {/* <MenuItem onClick={() => handleRename(selectedNotification!)}>
                          Edit
                        </MenuItem> */}
                        {deleteLoader === false ?
                          <MenuItem onClick={() => handleDelete(selectedNotification!)}>
                            Delete
                          </MenuItem> :
                          <MenuItem>
                            <CircularProgress size={24} />
                          </MenuItem>}
                  
                      </Menu>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Box style={{ textAlign: 'center', margin: '20px 0' }}>
        {fetching && allNotification.length > 0 && (
          <CircularProgress size={24} />
        )}
        {!fetching && hasMore && (
          <Button variant="contained"
            color="primary" onClick={handleLoadMore} disabled={fetching}>
            {fetching ? <CircularProgress size={24} /> : "Load More"}
          </Button>
        )}
      </Box>

 
    </>
  );
};

export default NotificationV1;
