import { RewardsAction, RewardsActions, RewardsState } from "../model/rewards";
import { Reducer } from "react";

const initialState: RewardsState = {
    fetching: false,
    creating: false,
    updating:false,
    // allQuestions: [],
    allReward: [],
    allSignUpReward:null,
    scoringToken:null,
    updateSignup:null



  };

  export const rewardsReducer: Reducer<
  RewardsState,
  RewardsAction
> = (
  state: RewardsState = initialState,
  action: RewardsAction
): RewardsState => {
  switch (action.type) {
    case RewardsActions.CREATE:
      return { ...state, creating: true, createError: undefined };
    case RewardsActions.CREATE_SUCCESS:
      return { ...state, creating: false, createError: undefined };
    case RewardsActions.CREATE_ERROR:
      return { ...state, creating: false, createError: action.payload };
    case RewardsActions.FETCH:
      return { ...state, fetching: true };
      case RewardsActions.FETCHED_ALL_REWARDS: {
        return {
          ...state,
        allReward: action.payload?.allReward || [], 
          // Update pagination token and related states
          fetching: action.payload?.loading ?? false,
          fetchError: action.payload?.error,
      
          // Save current filter and sort criteria for future comparisons
        };
      }
      case RewardsActions.FETCH_REWARD_SIGNUP: {
        return { ...state, fetching: true };
      }
      case RewardsActions.REWARD_SIGNUP_SUCCESS: {
        console.debug("qwsbjkqjkb", action.payload)
        return {
          ...state,
         
          allSignUpReward: action.payload?.signupReward, 
          // Update pagination token and related states
          fetching: action.payload?.loading ?? false,
          fetchError: action.payload?.error,
      
          // Save current filter and sort criteria for future comparisons
        };
      }
      case RewardsActions.UPDATE:
        return { ...state, fetching:true, updating:true };
      case RewardsActions.UPDATE_SUCCESS:
        return { ...state, updating: false, fetching:false  };

      case RewardsActions.UPDATE_SIGNUP_REWARD: {
        return { ...state, fetching: true };
      }
      case RewardsActions.UPDATE_SIGNUP_REWARD_SUCCESS: {
        console.debug("qwsbjkqjkb", action.payload)
        return {
          ...state,
         
          updateSignup: action.payload?.success, 
          // Update pagination token and related states
          fetching: action.payload?.loading ?? false,
          fetchError: action.payload?.error,
      
          // Save current filter and sort criteria for future comparisons
        };
      }

      case RewardsActions.FETCH_SCORING_TOKEN: {
        return { ...state, fetching: true };
      }
      case RewardsActions.SCORING_TOKEN_SUCCESS: {
        console.debug("qwsbjkqjkb", action.payload)
        return {
          ...state,
         
          scoringToken: action.payload?.scoringToken, 
          // Update pagination token and related states
          fetching: action.payload?.loading ?? false,
          fetchError: action.payload?.error,
      
          // Save current filter and sort criteria for future comparisons
        };
      }
      
    case RewardsActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    // case QuestionActions.FETCHED_QUESTIONS:
    //   return {
    //     ...state,
    //     fetching: false,
    //     allQuestions: action.payload || undefined,
    //   };
    default:
      return state;
  }
};
