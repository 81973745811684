import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { firestore } from "firebase";


export interface FetchFaqPayload {
  allFaq?: []; // Optional, because not all actions will provide projects
  loading?: boolean; // Optional
  error?: Error | string; // Optional

 
}

export interface FAQ {
  question: string;
  platform: string;
  context: string
  date: firestore.Timestamp;

}


export interface FAQState {
  fetching: boolean;
  // creating: boolean;
  // filter:string | null;
  // createError?: Error | string;
  error?: any;
  fetchError?: Error | string;
  allFaq: FAQ[] | [];

}

export enum FaqActions {
  FETCH = "FETCH_ALL_FAQ",
  FETCHED_ALL = "FETCHED_ALL_FAQ",
  FETCHED_FAQ = "FETCHED_FAQ",
  FETCH_ERROR = "FETCH_FAQ_ERROR",
  CREATE = "CREATE_FAQ",
  CREATE_SUCCESS = "CREATE_FAQ_SUCCESS",
  CREATE_ERROR = "CREATE_FAQ_ERROR",
  UPDATE = "UPDATE",
  UPDATE_SUCCESS = "UPDATE_SUCCESS",
  UPDATE_ERROR = "UPDATE_ERROR",
  DELETE = "DELETE",
  DELETE_SUCCESS = "DELETE_SUCCESS",
  DELETE_ERROR = "DELETE_ERROR"
}

interface FaqActionsType<T, P = {}> {
    type: T;
    payload?: P;
  }
  
  export type FaqAction =
    | FaqActionsType<typeof FaqActions.FETCH>
    | FaqActionsType<typeof FaqActions.FETCHED_ALL, FetchFaqPayload>
    | FaqActionsType<typeof FaqActions.CREATE>
    | FaqActionsType<typeof FaqActions.CREATE_SUCCESS>
    | FaqActionsType<typeof FaqActions.CREATE_ERROR, Error | string>
    | FaqActionsType<typeof FaqActions.UPDATE>
    | FaqActionsType<typeof FaqActions.UPDATE_SUCCESS>
    | FaqActionsType<typeof FaqActions.UPDATE_ERROR, Error | string>
    | FaqActionsType<typeof FaqActions.DELETE>
    | FaqActionsType<typeof FaqActions.DELETE_SUCCESS>
    | FaqActionsType<typeof FaqActions.DELETE_ERROR, Error | string>
    | FaqActionsType<
        typeof FaqActions.FETCHED_FAQ,
        FAQ[]
      >
    | FaqActionsType<typeof FaqActions.FETCH_ERROR, any>
  
  export type FaqThunk = ThunkAction<
    void,
    RootState,
    unknown,
    FaqAction
  >;
  export type FaqDispatch = ThunkDispatch<
    RootState,
    any,
    FaqAction
  >;