import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";

export type PlanStatus = "active" | "trialing" | "past_due";
export type UserRole = "admin" | "enterprise" | "regular";

export interface UserProfile {
  role: UserRole;
  planStatus?: PlanStatus;
  plan?: string;
  monthlyProjectsLeft?: number;
  token?: {
    claims: {
      stripeRole: string;
      [x: string]: any;
    };
  };
}

export interface UserState {
  isLoading: boolean;
  paymentLoading: boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  token?: string; 
  error?: string;
}

export enum UserActions {
  AUTH_LOADING = "AUTH_LOADING",
  AUTH_SUCCESS = "AUTH_SUCCESS",
  AUTH_FAILED = "AUTH_FAILED",
  START_PAYMENT = "START_PAYMENT",
  REDIRECTED = "REDIRECTED_TO_PAYMENT",
  ERROR = "ERROR",
  FETCH_TOKEN = "FETCH_TOKEN",
  FETCH_TOKEN_SUCCESS ="FETCH_TOKEN_SUCCESS",
}

interface UserActionType<T, P = {}> {
  type: T;
  payload?: P;
}

export type UserAction =
  | UserActionType<typeof UserActions.AUTH_LOADING>
  | UserActionType<typeof UserActions.AUTH_SUCCESS, firebase.User | undefined>
  | UserActionType<typeof UserActions.AUTH_FAILED, Error>
  | UserActionType<typeof UserActions.START_PAYMENT>
  | UserActionType<typeof UserActions.REDIRECTED>
  | UserActionType<typeof UserActions.FETCH_TOKEN>
  | UserActionType<typeof UserActions.FETCH_TOKEN_SUCCESS, string>
  | UserActionType<typeof UserActions.ERROR, any>;

export type UserThunk = ThunkAction<void, RootState, unknown, UserAction>;
export type UserDispatch = ThunkDispatch<RootState, any, UserAction>;
