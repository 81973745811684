import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

// components
import Layout from "./Layout/Layout";

// pages
import * as pages from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Loader from "./Loader/Loader";
import { UserDispatch } from "../model";
import { fetchAuthToken, redirectToPlan } from "../actions/user";
import BlockedDomainList from "../pages/blockedDomain/listOfBlockedDomain";
import BlockedUserDomainsList from "../pages/blockedDomain/listOfBlockedUser";

export default function App() {
  const auth = useSelector((state: RootState) => state.firebase.auth);
  const user = useSelector((state: RootState) => state.user);
  const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
  const dispatchUser = useDispatch<UserDispatch>();

  useEffect(()=>{

   
    dispatchUser(fetchAuthToken())
  
  },[auth])

  if (!isLoaded(auth)) return <Loader />;
  if (user.paymentLoading)
    return <Loader text="Redirecting to payment portal..." />;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        {/* <Route exact path="/myUploads" render={() => <Redirect to="/app/myUploads" />} /> */}
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <Route exact path="/subscription" component={Payment} />
        <Route path="/form" component={pages.Form} />
        <PrivateRoute path="/app" component={Layout} />
        <PrivateRoute path="/responses" component={Layout} />
        <PrivateRoute path="/create" component={pages.Create} />
        <PrivateRoute path="/create-notification" component={pages.CreateNotification} />

        <PrivateRoute path="/notification" component={Layout} />
        <PrivateRoute path="/blocked-domain" component={BlockedDomainList} />
        <PrivateRoute path="/create-factor" component={pages.CreateFactor} />
        <PrivateRoute path="/edit-factor/:id" component={pages.EditFactor} />
        <PrivateRoute path="/create-faq" component={pages.CreateFaq} />
        <PrivateRoute path="/edit/:id" component={pages.Edit} />
        <PrivateRoute path="/edit-faq/:id" component={pages.EditFaq}/>
        <PublicRoute path="/forgotPassword" component={pages.ForgotPassword} />
        <PublicRoute path="/login" component={pages.Login} />
        {/* <PublicRoute path="/Creates" component={pages.QuestionNewForm} /> */}

        <Route component={pages.Error} />
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

function Payment() {
  const location = useLocation();
  const dispatchPayment = useDispatch<UserDispatch>();
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");

  useEffect(() => {
    if (plan && !profile.plan) dispatchPayment(redirectToPlan(plan));
  });

  return (
    <Redirect
      to={{
        pathname: `/login?plan=${plan}`,
        state: {
          from: location,
        },
      }}
    />
  );
}
