import firebase from "firebase";
import { UserActions, UserThunk, UserAction } from "../model";
import { Auth } from "../repositories/auth";
import { Paywall } from "../repositories/payment";
import { getAuth } from "firebase/auth";

export const initializeUser = (user: firebase.User | null): UserAction => {
  return {
    type: UserActions.AUTH_SUCCESS,
    payload: user ?? undefined,
  };
};

export const loginUser = (email: string, password: string): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const auth_result = await Auth.signInWithEmail(email, password);
    if (auth_result instanceof Error)
      dispatch({ type: UserActions.AUTH_FAILED, payload: auth_result });
    else dispatch({ type: UserActions.AUTH_SUCCESS, payload: auth_result });
  };
};

export const loginUserWithGoogle = (): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const auth_result = await Auth.signInWithGoogle();
    if (auth_result instanceof Error)
      dispatch({ type: UserActions.AUTH_FAILED, payload: auth_result });
    else dispatch({ type: UserActions.AUTH_SUCCESS, payload: auth_result });
  };
};

export const registerUser = (
  name: string,
  lastName: string,
  email: string,
  sector: string,
  password: string,
  plan: string | null
): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const auth_result = await Auth.registerWithEmail(name, lastName, email, password,sector);
    if (auth_result instanceof Error)
      dispatch({ type: UserActions.AUTH_FAILED, payload: auth_result });
    else {
      if (plan) {
        dispatch({ type: UserActions.START_PAYMENT });
        const uid = auth_result.uid;
        const err = await Paywall.goToCheckout(uid, plan);
        if (err) dispatch({ type: UserActions.ERROR, payload: err });
        // dispatch({ type: UserActions.REDIRECTED });
      }
      dispatch({ type: UserActions.AUTH_SUCCESS, payload: auth_result });
    }
  };
};

export const signOutUser = (): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const error: void | Error = await Auth.signOut();
    if (!!error) dispatch({ type: UserActions.AUTH_FAILED, payload: error });
    else dispatch({ type: UserActions.AUTH_SUCCESS });
  };
};

export function redirectToPlan(plan: string): UserThunk {
  return async (dispatch, getState) => {
    dispatch({ type: UserActions.START_PAYMENT });
    const uid = getState().firebase.auth.uid;
    const err = await Paywall.goToCheckout(uid, plan);
    if (err) dispatch({ type: UserActions.ERROR, payload: err });
    // dispatch({ type: UserActions.REDIRECTED });
  };
}

export function redirectToCustomerPortal(): UserThunk {
  return async (dispatch) => {
    dispatch({ type: UserActions.START_PAYMENT });
    const err = await Paywall.goToCustomerPortal();
    if (err) dispatch({ type: UserActions.ERROR, payload: err });
  };
}

export function fetchAuthToken(): UserThunk {
  return async (dispatch) => {
    dispatch({ type: UserActions.FETCH_TOKEN }); // Indicate loading state

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        const token = await user.getIdToken(); // Get the authentication token
        dispatch({ type: UserActions.FETCH_TOKEN_SUCCESS, payload: token }); // Dispatch token
      } else {
        throw new Error("User is not authenticated");
      }
    } catch (err) {
      dispatch({ type: UserActions.AUTH_FAILED, payload: err }); // Handle error
    }
  };
}
