import React, { useState, Dispatch, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Button,
  Divider,
  // CircularProgress,
} from "@material-ui/core";
import {
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Feedback as FeedbackIcon,
} from "@material-ui/icons";
import { ReactComponent as LogoIcon } from "./logo.svg";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import { searchString } from "../../actions/search";
// styles
import useStyles from "./styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import icon from "../../images/download.png"
// import { showFeedbackModal } from "../../actions/layout";
import WeightModal from "./components/WeightModal";
import { fetchAuthToken, redirectToCustomerPortal, signOutUser } from "../../actions/user";
import { useDispatch, useSelector } from "react-redux";
import { LayoutAction, UserDispatch, SearchAction } from "../../model";
import { RootState } from "../../reducers";
import { getUserNotification, readNotification } from "../../service";


function Header(props: any) {
  const classes = useStyles();
  const history = useHistory();
  // global
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
  const dispatchUser = useDispatch<UserDispatch>();
  const user = useSelector((state: RootState) => state.firebase.auth);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const payment = useSelector((state: RootState) => state.user);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [flag, setFlag]= useState(true)
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [previousData, setPreviousData] = useState([])
  const [hideShowMore, setHideShowMore] = useState(false)
 


  const hasPlan =
    profile.token?.claims.stripeRole &&
    ["pro", "starter", "enterprise"].includes(profile.token.claims.stripeRole);
  const [numUnread, setUnRead] = useState(0);
  const [notificationData, setNotificationData] = useState([])

  const [notificationsMenu, setNotificationsMenu] = useState<
    EventTarget & HTMLButtonElement
  >();
  const [, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState<
    EventTarget & HTMLButtonElement
  >();
  const [subMenu, setSubMenu] = useState<
  EventTarget & HTMLButtonElement
>();


  // useEffect(function () {

  //   if(page == 1){ 
    
  //   getUserNotification(user.uid, 10 , page).then(response => {
  //     let data = response.data.data
  //    let metaTotal = response.data.metaTotal
  //    let statusCount = response.data.notificationStatus
  //    notificationDataCount(data, metaTotal, statusCount)
  //     if (data) {
  //       let previousData1 = []
  //       previousNotificationData(previousData1, data)
  //       setFlag(false)  
  //     }
    
  //   })
  // }
  // else{
  //   getUserNotification(user.uid, 10 , page).then(response => {
  //     let data = response.data.data
  //    let metaTotal = response.data.metaTotal
  //    let statusCount = response.data.notificationStatus
  //    if (data) {
  //     previousNotificationData(notificationData, data)
  //     setFlag(false)  
  //   }
  //    notificationDataCount(data, metaTotal, statusCount)
  //    if(metaTotal.totalPages == page){
  //     setHideShowMore(true)
  //    }
    
  //   })
  // }
  // }, [page])

  // function previousNotificationData(previousData1, currentData){
  //   let array = [...previousData1, ...currentData]
  //   setNotificationData(array)
  // }

  // function notificationDataCount(data, metaTotal, statusCount){
  //   if(metaTotal){
  //     setPage(metaTotal.currentPage)
  //     setTotalCount(metaTotal.totalCount)
  //     setTotalPageCount(metaTotal.totalPage)
  //    }
  //    if(statusCount){
  //     setUnRead(statusCount.unread)
  //    }
  // }
  

  // function unreadParticularNotifications(notificationId){
  //   if(notificationId == "all"){
  //     changeReadNotification(user.uid,notificationId)
  //   }
  //   else{ 
  //   let nId = [];
  //     nId.push(notificationId)
  //     nId.join(',');
  //     changeReadNotification(user.uid, nId)
  //   }
    
  // }

  //  function changeReadNotification(userId, notificationId){
  //   readNotification(userId, notificationId).then(response => {
  //     console.debug("unreadParticularNotifications",response)
  //     setFlag(true)
  //   })
  //  }

  // function viewAllNotification(){
   
  //   setPage(page+1)
  
  // }

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <WeightModal setOpen={setOpen} open={open}></WeightModal>
      <Toolbar className={classes.toolbar}>
        <Link to="/">
          <LogoIcon height="48px" />
        </Link>{" "}
        <div className={classes.grow} />
        <SearchBar
          className={classes.search}
          onChange={(q) => {
            setSearchQuery(q);
            dispatchSearch(searchString(q));
          }}
          onCancelSearch={() => {
            setSearchQuery("");
            dispatchSearch(searchString(""));
          }}
          value={searchQuery}
        />

        <IconButton
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={numUnread > 0 ? numUnread : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <Avatar alt={user.displayName!} src={user.photoURL!} />
        </IconButton>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(undefined)}
          className={classes.headerMenu}
          disableAutoFocusItem>
            {/* <div className="d-flex">
              <div>
          <h1 className={classes.NotificationTitle}>Notifications</h1>
          </div>
          <div className="pl-23 dropdown" type="none"> */}
          {/* <img src={icon}  /> */}
         
          {/* </div>
          </div> */}
          {/* {console.debug("notificationData", notificationData)} */}
          {numUnread === 0 ? (
            <MenuItem>
              <Typography className={classes.headerEmptyMenu}>
                No new notifications
              </Typography>
            </MenuItem>
          ) : (""
            // notificationData.length!=0 ?notificationData.map((n) => (
            //   <MenuItem
            //     key={n.id}
            //     onClick={() => setNotificationsMenu(undefined)}
            //     className={classes.headerMenuItem}
               
            //   >
            //     <Typography component={Link} to={""} 
            //     // onClick={unreadParticularNotifications(n.id)}
            //     >
            //       <div onClick={() =>unreadParticularNotifications(n.id)}>
            //       <h3 className={n.read == true ? "readNotificationTitle mb-0": "mb-0"}> {n.title}</h3>
            //     <span className={n.read == true ? "readNotificationTitle fs-13": "unReadNotificationTitle fs-13"}>{n.body}</span>
            //     </div>
            //     </Typography>
            //   </MenuItem>
            // )):[]
          )}
         {/* {hideShowMore == false ? <Button onClick={viewAllNotification}>more</Button>:""} */}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(undefined)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>

            <Avatar alt={user.displayName!} src={user.photoURL!} className={classes.large} />
            <Typography variant="body2" weight="bold" >
              {user.displayName ?? "Username"}
            </Typography>
            {/* {process.env.NODE_ENV === "development" && (
              <>
                <Typography>{user.uid}</Typography>
                <Typography>
                  Stripe role: {profile.token?.claims.stripeRole}
                </Typography>
              </>
            )} */}
            {/* <Typography variant="caption">
              {user.email ?? "Anonymous account"}
            </Typography> */}
          </div>
          {/* <MenuItem
            onClick={() => {
              if (hasPlan) dispatchUser(redirectToCustomerPortal());
              else window.location.assign("https://i3d.ai/payment");
            }}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            {payment.paymentLoading ? (
              <CircularProgress
                size="1rem"
                className={classes.profileMenuIcon}
              />
            ) : (
              <AccountIcon className={classes.profileMenuIcon} />
            )}{" "}
            {hasPlan ? "Customer Portal" : "Choose your plan!"}
          </MenuItem> */}
          {<div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation>
              {<Typography variant="caption">
                Manage Profile
              </Typography>}
            </Button>
          </div>}

          <div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation onClick={() => { history.push('/app/rewards') }}>
              {<Typography variant="caption">
                Manage Rewards
              </Typography>}
            </Button>
          </div>
          <div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation onClick={() => { history.push('/app/blocked-domain') }}>
              {<Typography variant="caption">
               Blocked Spam User
              </Typography>}
            </Button>
          </div>
          <div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation onClick={() => { history.push('/app/technical') }}>
              {<Typography variant="caption">
                Manage Technical
              </Typography>}
            </Button>
          </div>
          <div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation onClick={() => { setOpen(true) }}>
              <Typography variant="caption">
                Manage weights
              </Typography>
            </Button>
          </div>
          <div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation onClick={() => {history.push('/app/notifications') }}>
              <Typography variant="caption">
               Notification
              </Typography>
            </Button>
          </div>
          <Divider light />
          <div className={classes.profileMenuUser}>
            <Button variant="outlined" color="primary"
              className={classes.profileMenuLink}
              startIcon={<ExitToAppIcon />}
              onClick={() => dispatchUser(signOutUser())} disableElevation>
              {<Typography variant="caption">
                Sign Out
              </Typography>}
            </Button>
          </div>
        </Menu>
        <div>
          <ShoppingCartIcon style={{ color: "#858C94" }} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default Header;