import React, { Dispatch, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button as MButton,
} from "@material-ui/core";
import useStyles from "./styles";
import {FaqForm}  from "./components";
import FadeIn from "react-fade-in";
import Loader from "../../components/Loader/Loader";
import {QuestionRepo} from "../../repositories/question";
import { FaqDispatch, Faqs } from "../../model";
import { useHistory , useParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateFaqs } from "../../actions/faq";

const validationSchema = Yup.object().shape<Faqs>({
    question: Yup.string().required("this field is necessary"),
    context: Yup.string(),
    platform: Yup.string(),
  
});



function CreateFaq() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch<FaqDispatch>();
  
  const { id } = useParams<{ id: string }>();
  const [data,SetData]= React.useState<Faqs>();
  useEffect(() => {
    const res = QuestionRepo.fetchFaq(id).then((result)=>{
        SetData(result);
    })
}, []);
  if (!data) return <Loader text="fetching the question..." />;

  const intitialValues: Faqs = {
    question: data.question,
    context: data.context,
    platform: data.platform
  };

  return (
    <div className={classes.root}>
      <Formik<Faqs>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(updateFaqs(values,id, history));
        
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                  <FaqForm/>
                  <div style={{ marginBottom: 40 }} />
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateFaq;
