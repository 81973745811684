import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Add as AddIcon, ChevronRight } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import { FaqList, QuestionList } from "../../components/QuestionList";
import { RootState } from "../../reducers";
import {
    Faqs,
	SearchAction,
} from "../../model";
import _ from "lodash";
import { QuestionRepo } from "../../repositories/question";
import Loader from "../../components/Loader/Loader";
import { FaqDispatch } from "../../model/faq";
import { fetchAllFaqs } from "../../actions/faq";


const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
		fontWeight: "bold",
	},
}))(TableCell);

function Faq() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const keys = [
		{id:1,label:""},
		{id:2,label:"Question"},
		{id:3,label:"platform"},
		{id:4,label:"context"},
	];
	
	const search = useSelector((state: RootState) => state.search);
	const {allFaq, fetching} = useSelector((state: RootState) => state.faq);

	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const [questions, setQuestions] = useState([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const dispatchFaq = useDispatch<FaqDispatch>();
	
	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);
	useEffect(() => {
       
		dispatchFaq(fetchAllFaqs())
		// setQuestions(res)
    
	}, [search.searchString]);

	useEffect(() =>{
		console.debug("qxhbjqbhjq", allFaq)
		setQuestions(allFaq);
	}, [allFaq])

    // if (!questions.length)
    // return <Loader text="Fetching your Questions..." />;

	return (
		<>
			<Fab
				className={classes.fab}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={goToCreate}
			>
				<AddIcon />
				New Question
			</Fab>
			<Fab
				className={classes.fab1}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={()=>{history.push('/create-faq')}}
			>
				<AddIcon />
				New Faq Question
			</Fab>
			<>
				
				{ questions.length >0 && <div className={classes.container}>
					
						<TableContainer>
						<Table stickyHeader className="mb-0">
							<TableHead>
								<TableRow>
									{keys.map((key) => (
										<StyledTableCell key={key.id}>{key.label}</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								
					{questions.length>0 && questions.map((v, index) => {									
						return <FaqList key={index} data={v}/>															
					})}
							</TableBody>
						</Table>
					</TableContainer>
					
				</div> }
			</>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
		return history.push("/create");
	}
}



export default Faq;