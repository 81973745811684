import React, { useEffect, useState } from "react";

import {
	Fab,
	Input,
	Menu,
	MenuItem,
	Select,
	Table,
	Button,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { Typography } from "../../components/Wrappers/Wrappers";
import { FaqDispatch, Questions } from "../../model";
import { QuestionRepo } from "../../repositories/question";
import { useDispatch } from "react-redux";
import { deleteFaq } from "../../actions/faq";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);



function FaqList(props: { data: Questions }) {

	const { data } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();

	const dispatch = useDispatch<FaqDispatch>();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		history.push("/edit-faq/" + data.id)
	};

	const deleteQuestion = () => {
		setAnchorEl(null);
		dispatch(deleteFaq(data.id, history))
		
	};


	return (
		<StyledTableRow>
			<TableCell>
			</TableCell>
			<TableCell>
				<Typography weight="bold" variant="body1">
					{data.question}
				</Typography>
			</TableCell>
            <TableCell>
				{data.platform}
			</TableCell>
			<TableCell>
				{data.context}
			</TableCell>
			<TableCell>
				<Button aria-controls="simple-menu" color="primary" aria-haspopup="true" onClick={handleClick}>
					Action
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleEdit}>Edit</MenuItem>
					<MenuItem onClick={deleteQuestion}>Delete</MenuItem>
				</Menu>
			</TableCell>
		</StyledTableRow>

	);
}

export { FaqList };
