import { FaqAction, FaqActions, FAQState } from "../model/faq";
import { Reducer } from "react";

const initialState: FAQState = {
    fetching: false,
    allFaq: [],

  };

  export const faqReducer: Reducer<
  FAQState,
  FaqAction
> = (
  state: FAQState = initialState,
  action: FaqAction
): FAQState => {
  switch (action.type) {

    case FaqActions.FETCH:
      return { ...state, fetching: true };
      case FaqActions.FETCHED_ALL: {
        console.debug("FaqActions:FaqActions", action.payload)
        return {
          ...state,
          allFaq: [...(action.payload?.allFaq || [])],
          fetching: action.payload?.loading ?? false,
          fetchError: action.payload?.error,

        };
      }
    case FaqActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    // case FaqActions.FETCHED_FAQ:
    //   return {
    //     ...state,
    //     fetching: false,
    //     allFaq: action.payload || undefined,
    //   };
    default:
      return state;
  }
};
