import { QuestionState, QuestionAction, QuestionActions } from "../model/question";
import { Questions } from "../model";
import { Reducer } from "react";

const initialState: QuestionState = {
    fetching: false,
    creating: false,
    // allQuestions: [],
    allQuestions: [],
    filter:null,
    nextPageToken: null, // New token for pagination
    hasMore: true,       // Indicates if there are more pages to fetch
    previousFilter:null,
    previousSortBy:null,
    previousSortType:null,
    response:null

  };

  export const questionsReducer: Reducer<
  QuestionState,
  QuestionAction
> = (
  state: QuestionState = initialState,
  action: QuestionAction
): QuestionState => {
  switch (action.type) {
    case QuestionActions.CREATE:
      return { ...state, creating: true, createError: undefined };
    case QuestionActions.CREATE_SUCCESS:
      return { ...state, creating: false, createError: undefined };
    case QuestionActions.CREATE_ERROR:
      return { ...state, creating: false, createError: action.payload };
    case QuestionActions.FETCH:
      return { ...state, fetching: true };
      case QuestionActions.FETCHED_ALL: {
        const hasFilterChanged = state.previousFilter !== action.payload?.filter;
         console.debug("hasFilterChanged:hasFilterChanged", hasFilterChanged)
         console.debug("hasFilterChanged:hasFilterChanged",  [...state.allQuestions, ...(action.payload?.questions || [])])

        return {
          ...state,
         
          allQuestions:hasFilterChanged ? [...(action.payload?.questions || [])] : [...state.allQuestions, ...(action.payload?.questions || [])],
            
          // Update pagination token and related states
          nextPageToken: action.payload?.nextPageToken || null,
          hasMore: action.payload?.hasMore || false,
          fetching: action.payload?.loading ?? false,
          fetchError: action.payload?.error,
      
          // Save current filter and sort criteria for future comparisons
          previousFilter: action.payload?.filter,
          // previousSortBy: action.payload?.sortBy,
          // previousSortType:action.payload?.sortingType
        };
      }
      case QuestionActions.QUESTION_RESPONSE_SUCCESS:
        console.debug("QuestionActions.QUESTION_RESPONSE_SUCCESS", action.payload)
      return { ...state, fetching: true, response: action.payload };
    case QuestionActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    // case QuestionActions.FETCHED_QUESTIONS:
    //   return {
    //     ...state,
    //     fetching: false,
    //     allQuestions: action.payload || undefined,
    //   };
    default:
      return state;
  }
};
